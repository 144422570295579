var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardBody", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("pages.owner.ownerOrderDetail.identifier", {
                orderId: _vm.shortOrderId,
                guestName: _vm.order.customerName
              })
            ) +
            " | " +
            _vm._s(
              _vm.$t("pages.owner.ownerOrderDetail.status", {
                status: _vm.status
              })
            ) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }