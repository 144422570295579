var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardBody",
        [
          !_vm.canBeChanged
            ? _c(
                "CRow",
                [
                  _c("CCol", {
                    staticClass: "content-left",
                    attrs: { md: "6" }
                  }),
                  _c(
                    "CCol",
                    { staticClass: "content-right", attrs: { md: "6" } },
                    [
                      _vm._v(
                        " Importiere Buchungen bitte im ursprünglichen Buchungssystem bearbeiten. "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.canBeChanged
            ? _c(
                "CRow",
                [
                  _c("CCol", {
                    staticClass: "content-left",
                    attrs: { md: "6" }
                  }),
                  _vm.canBeRejected || _vm.canBeAccepted || _vm.canBeCanceled
                    ? _c(
                        "CCol",
                        { staticClass: "content-right", attrs: { md: "6" } },
                        [
                          _vm.canBeRejected
                            ? _c(
                                "CButton",
                                {
                                  staticClass: "mr10",
                                  attrs: {
                                    color: "danger",
                                    disabled: _vm.showSpinnnerInRejectButton
                                  },
                                  on: { click: _vm.handleReject }
                                },
                                [
                                  _vm.showSpinnnerInRejectButton
                                    ? _c("CSpinner", { attrs: { size: "sm" } })
                                    : _vm._e(),
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "components.hostOrderDetailButtons.reject",
                                        expression:
                                          "'components.hostOrderDetailButtons.reject'"
                                      }
                                    ]
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canBeAccepted
                            ? _c(
                                "CButton",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.showSpinnnerInAcceptButton
                                  },
                                  on: { click: _vm.handleAccept }
                                },
                                [
                                  _vm.showSpinnnerInAcceptButton
                                    ? _c("CSpinner", { attrs: { size: "sm" } })
                                    : _vm._e(),
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "components.hostOrderDetailButtons.accept",
                                        expression:
                                          "'components.hostOrderDetailButtons.accept'"
                                      }
                                    ]
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canBeCanceled
                            ? _c(
                                "CButton",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.showSpinnnerInCancelButton
                                  },
                                  on: { click: _vm.handleCancel }
                                },
                                [
                                  _vm.showSpinnnerInCancelButton
                                    ? _c("CSpinner", { attrs: { size: "sm" } })
                                    : _vm._e(),
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "components.hostOrderDetailButtons.cancel",
                                        expression:
                                          "'components.hostOrderDetailButtons.cancel'"
                                      }
                                    ]
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("BookingButtonsModal", {
        attrs: {
          show: _vm.showConfirmationModal,
          title: _vm.title,
          label: _vm.label,
          btnLabel: _vm.btnLabel
        },
        on: { onUpdateShow: _vm.onUpdateShow, onClose: _vm.onCloseModal }
      }),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t(
              "components.hostOrderDetailButtons.cancelModal.title"
            ),
            color: "warning",
            show: _vm.showCancelModal,
            centered: true,
            closeOnBackdrop: false,
            size: "lg"
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "secondary", "data-testid": "cancel" },
                      on: { click: _vm.closeConfirmationModal }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("forms.common.cancel")) + " ")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "primary", "data-testid": "ok" },
                      on: { click: _vm.onCancel }
                    },
                    [_vm._v(_vm._s(_vm.$t("forms.common.ok")))]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("ValidatedInput", {
            attrs: {
              label: _vm.$t(
                "components.hostOrderDetailButtons.cancelModal.cancellationFeeInPercentageLabel"
              ),
              field: _vm.$v.cancellationFeeInPercentage,
              value: _vm.$v.cancellationFeeInPercentage.$model,
              type: "number",
              translationKey: "cancelModal.cancellationFeeInPercentage"
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(
                  _vm.$v.cancellationFeeInPercentage,
                  "$model",
                  $event
                )
              }
            }
          }),
          _c("ValidatedTextArea", {
            attrs: {
              label: _vm.$t(
                "components.hostOrderDetailButtons.cancelModal.cancelationMessageLabel"
              ),
              field: _vm.$v.cancelationMessage,
              value: _vm.$v.cancelationMessage.$model,
              translationKey: "cancelModal.HostMessage",
              type: "number"
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.$v.cancelationMessage, "$model", $event)
              }
            }
          })
        ],
        1
      ),
      _c("Notification", {
        attrs: {
          text: _vm.getNotificationMessage,
          show: _vm.showSuccessNotification
        },
        on: {
          onClose: function() {
            return _vm.handleCloseSuccessNotification()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }