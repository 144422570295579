var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "components.HostOrderNotesPart.notes",
            expression: "'components.HostOrderNotesPart.notes'"
          }
        ]
      }),
      _c(
        "CCardBody",
        [
          _c("ValidatedTextArea", {
            attrs: {
              field: _vm.$v.order.orderNotes,
              value: _vm.$v.order.orderNotes.$model,
              "data-cy": "orderNotes",
              translationKey: "order.orderNotes"
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.$v.order.orderNotes, "$model", $event)
              }
            }
          }),
          _c("ValidatedTextArea", {
            attrs: {
              field: _vm.$v.order.guestNotes,
              value: _vm.$v.order.guestNotes.$model,
              "data-cy": "guestNotes",
              translationKey: "order.guestNotes"
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.$v.order.guestNotes, "$model", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "CCardFooter",
        [
          _c("SaveButton", {
            attrs: { onSave: _vm.onSave, model: _vm.$v.order }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }