var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CModal",
    {
      staticClass: "confirmation-modal",
      attrs: { show: _vm.show, title: _vm.title },
      on: {
        "update:show": function($event) {
          return _vm.onClose()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("CButton", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "components.hostOrderDetailButtons.cancelBtn",
                    expression: "'components.hostOrderDetailButtons.cancelBtn'"
                  }
                ],
                attrs: { color: "light", disabled: _vm.saving },
                on: {
                  click: function($event) {
                    return _vm.onClose()
                  }
                }
              }),
              _c(
                "CButton",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.canSaveChange || _vm.saving
                  },
                  on: {
                    click: function($event) {
                      return _vm.onUpdateShow()
                    }
                  }
                },
                [
                  _vm.saving
                    ? _c("CSpinner", { attrs: { size: "sm" } })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.btnLabel) + " ")
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _vm._v(" " + _vm._s(this.label) + " "),
          _c("ValidatedTextArea", {
            attrs: {
              field: _vm.$v.message,
              value: _vm.$v.message.$model,
              translationKey: "note"
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.$v.message, "$model", $event)
              }
            }
          }),
          _c("ValidatedCheckbox", {
            attrs: {
              field: _vm.$v.acceptBusinessTerms,
              checked: _vm.$v.acceptBusinessTerms.$model,
              label: _vm.$t(
                "components.OrderEditConfirmationModal.confirmationStatement"
              )
            },
            on: {
              "update:checked": function($event) {
                return _vm.$set(_vm.$v.acceptBusinessTerms, "$model", $event)
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }