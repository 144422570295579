var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "components.ServiceOptions.editServicesOptionsTitle",
            expression: "'components.ServiceOptions.editServicesOptionsTitle'"
          }
        ]
      }),
      _c(
        "CCardBody",
        { class: { disabledDiv: !_vm.order.isBookingEditable } },
        _vm._l(_vm.$v.order.orderedPlayroomAdditions.$each.$iter, function(
          additionOption,
          index
        ) {
          return _c(
            "CRow",
            {
              key: "rowIndex" + index,
              staticClass: "additional-options-modal-row"
            },
            [
              _vm.showOption(additionOption.bookingType.$model)
                ? [
                    _c(
                      "CCol",
                      { staticClass: "text-left", attrs: { md: "4" } },
                      [
                        _c("ValidatedCheckbox", {
                          attrs: {
                            field: additionOption.hasChecked,
                            checked: additionOption.hasChecked.$model,
                            label: additionOption.additionName.$model
                          },
                          on: {
                            "update:checked": function($event) {
                              return _vm.$set(
                                additionOption.hasChecked,
                                "$model",
                                $event
                              )
                            },
                            change: function() {
                              return _vm.onHasCheckedChange(additionOption)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      { staticClass: "text-left", attrs: { md: "4" } },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.BookingCard.AdditionOptionsService." +
                                    _vm.translationKey(
                                      additionOption,
                                      _vm.order.bookingType
                                    ),
                                  {
                                    price: _vm.formatCurrency(
                                      _vm.isHourlyBooking
                                        ? additionOption.priceForHourly.$model
                                        : additionOption.priceForOvernight
                                            .$model
                                    )
                                  }
                                )
                              ) +
                              " "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "CCol",
                      { staticClass: "text-left", attrs: { md: "4" } },
                      [
                        additionOption.isPricePerPiece.$model
                          ? _c(
                              "span",
                              [
                                _c(
                                  "CButton",
                                  {
                                    attrs: { color: "secondary", size: "sm" },
                                    on: {
                                      click: function($event) {
                                        return _vm.minus(additionOption)
                                      }
                                    }
                                  },
                                  [
                                    _c("CIcon", { attrs: { name: "cisMinus" } })
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "amount" }, [
                                  _vm._v(_vm._s(additionOption.count.$model))
                                ]),
                                _c(
                                  "CButton",
                                  {
                                    attrs: { color: "secondary", size: "sm" },
                                    on: {
                                      click: function($event) {
                                        return _vm.plus(additionOption)
                                      }
                                    }
                                  },
                                  [_c("CIcon", { attrs: { name: "cisPlus" } })],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }