var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "forms.discountExtraCharge.title",
            expression: "'forms.discountExtraCharge.title'"
          }
        ]
      }),
      _c(
        "CCardBody",
        { class: { disabledDiv: !_vm.order.isBookingEditable } },
        [
          _vm._l(_vm.$v.order.orderedDiscountExtraCharge.$each.$iter, function(
            discountExtraCharge,
            index
          ) {
            return _c(
              "CCard",
              { key: "rowIndex" + index },
              [
                _c(
                  "CCardBody",
                  [
                    _c(
                      "CCol",
                      { attrs: { md: "12" } },
                      [
                        _c("ValidatedInput", {
                          attrs: {
                            translationKey: "discountExtraCharge.optionName",
                            field: discountExtraCharge.optionName,
                            value: discountExtraCharge.optionName.$model
                          },
                          on: {
                            "update:value": function($event) {
                              return _vm.$set(
                                discountExtraCharge.optionName,
                                "$model",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      { attrs: { md: "12" } },
                      [
                        _c("ValidatedSelect", {
                          attrs: {
                            translationKey:
                              "discountExtraCharge.discountExtraCharge",
                            options: _vm.getOptions,
                            field: discountExtraCharge.option,
                            value: discountExtraCharge.option.$model
                          },
                          on: {
                            "update:value": function($event) {
                              return _vm.$set(
                                discountExtraCharge.option,
                                "$model",
                                $event
                              )
                            },
                            change: function($event) {
                              return _vm.discountTypeChange(discountExtraCharge)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      { attrs: { md: "12" } },
                      [
                        _c("ValidatedInput", {
                          attrs: {
                            translationKey: "discountExtraCharge.amount",
                            field: discountExtraCharge.amount,
                            type: "number",
                            value: discountExtraCharge.amount.$model
                          },
                          on: {
                            "update:value": function($event) {
                              return _vm.$set(
                                discountExtraCharge.amount,
                                "$model",
                                $event
                              )
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append-content",
                                fn: function() {
                                  return [
                                    _c("CIcon", { attrs: { name: "cis-euro" } })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      { attrs: { md: "12" } },
                      [
                        _c("ValidatedTextArea", {
                          attrs: {
                            translationKey: "discountExtraCharge.comment",
                            field: discountExtraCharge.comment,
                            value: discountExtraCharge.comment.$model
                          },
                          on: {
                            "update:value": function($event) {
                              return _vm.$set(
                                discountExtraCharge.comment,
                                "$model",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      { staticClass: "right-btn", attrs: { md: "12" } },
                      [
                        _c("CButton", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "forms.discountExtraCharge.deleteBtn",
                              expression:
                                "'forms.discountExtraCharge.deleteBtn'"
                            }
                          ],
                          staticClass: "mr10",
                          attrs: { color: "danger" },
                          on: {
                            click: function() {
                              return _vm.openDeleteModal(index)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c("DeleteConfirmationModal", {
            attrs: {
              show: _vm.showDeleteModal,
              message: _vm.$t("forms.discountExtraCharge.deleteMsg")
            },
            on: { delete: _vm.internalOnDelete, close: _vm.closeDeleteModal }
          }),
          _c(
            "CButton",
            {
              staticClass: "add-button",
              attrs: { color: "secondary" },
              on: {
                click: function() {
                  return _vm.addDiscountExtraCharge()
                }
              }
            },
            [
              _c("CIcon", { attrs: { name: "cilPlus" } }),
              _c("span", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "forms.discountExtraCharge.addBtn",
                    expression: "'forms.discountExtraCharge.addBtn'"
                  }
                ],
                staticClass: "add-text"
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }