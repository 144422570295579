var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.ready ? _c("CSpinner", { staticClass: "show-center" }) : _vm._e(),
      _vm.ready
        ? _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c("HostOrderDetailTitlePart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("HostOrderDetailGeneralInformationPart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("HostOrderDetailButtons", { attrs: { order: _vm.order } }),
                  _c("HostOrderDetailCalendarPart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("HostOrderNotesPart", { attrs: { order: _vm.order } }),
                  _c("OrderDetailPricePart", {
                    attrs: {
                      order: _vm.order,
                      showTax: true,
                      showHostInfo: true
                    }
                  }),
                  _c("OrderDetailImportantInformationPart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("OrderDetailEventsPart", { attrs: { order: _vm.order } }),
                  _c("HostOrderDetailButtons", { attrs: { order: _vm.order } })
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _c("PlayroomBookingEditCard", {
                    attrs: { order: _vm.order },
                    on: { reloadData: _vm.handleReloadData }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }