var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-basic-settings" },
    [
      _c(
        "CCard",
        [
          _c("CCardHeader", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "components.EditBasicSettingPart.editBasicSettingsTitle",
                expression:
                  "'components.EditBasicSettingPart.editBasicSettingsTitle'"
              }
            ]
          }),
          _c(
            "CCardBody",
            { class: { disabledDiv: !_vm.order.isBookingEditable } },
            [
              _c("BookingTypePart", { attrs: { order: _vm.order } }),
              _vm.order.bookingType === "hourly"
                ? _c("HourlyBookingPart", { attrs: { order: _vm.order } })
                : _vm._e(),
              _vm.order.bookingType === "overnight"
                ? _c("OvernightBookingPart", { attrs: { order: _vm.order } })
                : _vm._e(),
              _c("GuestPart", { attrs: { order: _vm.order } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }