var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardBody",
        [
          _c(
            "CRow",
            [
              _c("CCol", { attrs: { md: "6" } }, [
                _c("div", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value:
                        "components.OrderDetailGeneralInformationPart.orderInformations",
                      expression:
                        "'components.OrderDetailGeneralInformationPart.orderInformations'"
                    }
                  ],
                  staticClass: "orderInformations"
                }),
                _c("br"),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.OrderDetailGeneralInformationPart.playroomName",
                          { name: _vm.order.playroomName }
                        )
                      ) +
                      " "
                  )
                ]),
                _vm.order.bookingType == "overnight"
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailGeneralInformationPart.overnight"
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                !_vm.order.bookingType == "nightly"
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailGeneralInformationPart.nightly"
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "components.OrderDetailGeneralInformationPart.guestCount",
                          _vm.order.guestCount,
                          { count: _vm.order.guestCount }
                        )
                      ) +
                      ", "
                  ),
                  _vm.order.bookingType.toUpperCase() == "Hourly".toUpperCase()
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                "components.OrderDetailGeneralInformationPart.hourCount",
                                Math.ceil(_vm.order.hourCount),
                                {
                                  count: _vm.order.hourCount.toLocaleString(
                                    "de"
                                  )
                                }
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.order.bookingType.toUpperCase() ==
                  "Overnight".toUpperCase()
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                "components.OrderDetailGeneralInformationPart.dayCount",
                                _vm.order.dayCount,
                                { count: _vm.order.dayCount }
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", { attrs: { "data-testid": "checkInCheckOut" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.OrderDetailGeneralInformationPart.checkIn"
                        )
                      ) +
                      " " +
                      _vm._s(_vm.formatIsoDateTime(_vm.order.start)) +
                      " "
                  )
                ]),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.OrderDetailGeneralInformationPart.checkOut"
                        )
                      ) +
                      " " +
                      _vm._s(_vm.formatIsoDateTime(_vm.order.end)) +
                      " "
                  )
                ])
              ]),
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value:
                          "components.OrderDetailGeneralInformationPart.guestInformations",
                        expression:
                          "'components.OrderDetailGeneralInformationPart.guestInformations'"
                      }
                    ],
                    staticClass: "orderInformations"
                  }),
                  _c("br"),
                  _vm.order.customerName
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.OrderDetailGeneralInformationPart.name"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.order.customerName) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.order.customerEmail
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.OrderDetailGeneralInformationPart.email"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.order.customerEmail) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.order.customerPhone
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.OrderDetailGeneralInformationPart.phoneNumber"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.order.customerPhone) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.OrderDetailGeneralInformationPart.guestCreatedAt"
                          )
                        ) +
                        " " +
                        _vm._s(
                          _vm.formatIsoDateTime(_vm.order.guestCreatedAt, "P")
                        ) +
                        " "
                    )
                  ]),
                  _vm.order.guestIsDeleted
                    ? _c("div", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "components.OrderDetailGeneralInformationPart.guestIsDeleted",
                            expression:
                              "'components.OrderDetailGeneralInformationPart.guestIsDeleted'"
                          }
                        ]
                      })
                    : _vm._e(),
                  !_vm.order.guestIsDeleted
                    ? _c("div", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "components.OrderDetailGeneralInformationPart.guestIsNotDeleted",
                            expression:
                              "'components.OrderDetailGeneralInformationPart.guestIsNotDeleted'"
                          }
                        ]
                      })
                    : _vm._e(),
                  _vm.order.isNewGuest
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.OrderDetailGeneralInformationPart.isNewGuest"
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  !_vm.order.isNewGuest
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.OrderDetailGeneralInformationPart.isNotNewGuest"
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _c("br"),
                  _vm.canMessageToGuest
                    ? _c(
                        "CButton",
                        {
                          staticClass: "message-to-guest",
                          attrs: { color: "secondary" },
                          on: { click: _vm.messageToGuest }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "pages.owner.ownerOrderDetail.writeMessage",
                                  { guestName: _vm.order.customerName }
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  !_vm.canMessageToGuest
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "c-tooltip",
                              rawName: "v-c-tooltip",
                              value: {
                                appendToBody: true,
                                content: _vm.$t(
                                  "pages.owner.ownerOrderDetail.tooltipGuestIsDeleted"
                                )
                              },
                              expression:
                                "{\n            appendToBody: true,\n            content: $t('pages.owner.ownerOrderDetail.tooltipGuestIsDeleted'),\n          }"
                            }
                          ],
                          staticClass: "message-to-guest"
                        },
                        [
                          !_vm.canMessageToGuest
                            ? _c(
                                "CButton",
                                {
                                  attrs: { color: "secondary", disabled: "" },
                                  on: { click: _vm.messageToGuest }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "pages.owner.ownerOrderDetail.writeMessageDeletedAccount"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }