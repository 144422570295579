var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info" }, [
    _vm.order.isBookingEditable
      ? _c("div", {
          directives: [
            {
              name: "t",
              rawName: "v-t",
              value: "components.PlayroomBookingEditInfoPart.info",
              expression: "'components.PlayroomBookingEditInfoPart.info'"
            }
          ]
        })
      : _vm._e(),
    !_vm.order.isBookingEditable
      ? _c("div", {
          directives: [
            {
              name: "t",
              rawName: "v-t",
              value: "components.PlayroomBookingEditInfoPart.disableInfo",
              expression: "'components.PlayroomBookingEditInfoPart.disableInfo'"
            }
          ]
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }